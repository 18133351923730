'use strict';
var Cookies = require('js-cookie/src/js.cookie');
var breakpoints = require('../components/breakpoints');

module.exports = {

    showModal: function () {
        var $emailSignupModal = $('.email-signup-modal');
        var $hideOnMobile = ($emailSignupModal.hasClass('js-disabled-mobile') && breakpoints.isLowRes()) || false;
        /**
         * Displays the modal after the timeout concludes and/or during an exit intent scenario.
         */
        function displayModal() {
            var domain = window.location.host.replace('www.', '');
            var cookieDays = $emailSignupModal.data('expires') || 'session'; // days until cookie expires (or 'session')
            var cookieOptions = {
                // path: '/', // the default path is '/'.
                domain: domain
            };
            if (cookieDays !== 'session') {
                cookieOptions.expires = cookieDays;
            } // if cookieDays IS 'session', do NOT set expires on the cookieOptions object.

            $emailSignupModal.modal('show');
            // set the cookie
                Cookies.set(cookieName, 1, cookieOptions); // eslint-disable-line
        }
        if ($emailSignupModal.length) {
            var cookieName = $emailSignupModal.data('cookie') || 'unnamedModal';
            if (!Cookies.get(cookieName) && (!$hideOnMobile)) {
                window.setTimeout(function () {
                    displayModal();
                }, $emailSignupModal.data('timeout'));
            }
        }
    },
    submitForm: function () {
        $('.email-signup-popup-form').on('submit', function (e) {
            e.preventDefault();
            var $form = $(this);
            var url = $form.attr('action');
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: $form.serialize(),
                success: function (data) {
                    if (data.success) {
                        $('.signup-form-container').addClass('d-none');
                        $('.signup-success-container').removeClass('d-none'); // Checkout shipping radio

                        if ('dtmLayer' in data && 'digitalData' in window) {
                            if (data.dtmLayer.page && data.dtmLayer.page.form &&
                                window.digitalData.page && window.digitalData.page.form) {
                                window.digitalData.page.form.registrationType = data.dtmLayer.page.form.registrationType;
                            }

                            if (data.dtmLayer.user) {
                                window.digitalData.user = data.dtmLayer.user;
                            }
                        }
                    } else {
                        $form.find('.email-subscribed-error').removeClass('d-none');
                    }
                }
            });
        });
    },
    showDetails: function () {
        $('.details-btn-link').on('click', function () {
            $('.signup-form-container').addClass('d-none');
            $('.details-back-btn').removeClass('d-none').addClass('d-flex');
            $('.signup-details-container').removeClass('d-none');
        });
    },
    backButton: function () {
        $('.details-back-btn').on('click', function () {
            $('.signup-form-container').removeClass('d-none');
            $('.details-back-btn').addClass('d-none').removeClass('d-flex');
            $('.signup-details-container').addClass('d-none');
        });
    }
};
